import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    display: block;
    width: 100%;
    background: #0e1828;
    color: #fff;
    &.white {
        color: #0e1828;
        background: #fff;
        .outer-wrapper {
            .inner-wrapper {
                .column {
                    h2 {
                        color: #0e1828;
                    }
                }
            }
        }
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            padding: 7rem 3.8rem;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                h2 {
                    display: block;
                    font-family: 'Source Serif Pro';
                    font-size: 4rem;
                    line-height: 1.25;
                    font-weight: 400;
                    font-style: italic;
                    text-align: center;
                    color: #fff;
                    margin: 0 auto 1rem auto;
                    max-width: 775px;
                    width: 100%;
                }
                .content {
                    display: block;
                    text-align: center;
                    max-width: 575px;
                    margin: 0 auto;
                    > p {
                        font-family: 'Poppins';
                        font-size: 1.4rem;
                        line-height: 1.35;
                        font-weight: 300;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                padding: ${(props) =>
                    props.noPaddingTop ? '0 3.8rem 10rem 3.8rem' : '10rem 3.8rem'};
                align-items: center;
            }
        }
    }
`;

const IntroText = ({ ...props }) => {
    return (
        <Wrapper className={props.background} noPaddingTop={props.noPaddingTop}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column">
                        <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        <div className="content">
                            <p dangerouslySetInnerHTML={{ __html: props.content }} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default IntroText;
