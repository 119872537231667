import slide1ImageDesktop from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-1--desktop.jpg';
import slide1ImageMobile from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-1--mobile.jpg';
import slide2ImageDesktop from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-2--desktop.jpg';
import slide2ImageMobile from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-2--mobile.jpg';
import slide3ImageDesktop from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-3--desktop.jpg';
import slide3ImageMobile from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-3--mobile.jpg';
import slide4ImageDesktop from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-4--desktop.jpg';
import slide4ImageMobile from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-4--mobile.jpg';
import slide5ImageDesktop from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-5--desktop.jpg';
import slide5ImageMobile from '../../../images/digital-marketing/carousel/digital-marketing-page-slide-5--mobile.jpg';

export const sliderData = [
    {
        desktopImage: slide4ImageDesktop,
        mobileImage: slide4ImageMobile,
        subtitle: 'Digital marketing services',
        heading: 'We design<br/ > <span class="italic">with Digital in mind</span>',
        content:
            'Our digital designers take the client on the journey with them, holding workshops for design exploration, building wireframes, establishing UX and defining execution stages.',
    },
    {
        desktopImage: slide1ImageDesktop,
        mobileImage: slide1ImageMobile,
        subtitle: 'Digital marketing services',
        heading: 'Branding for new<br/ > <span class="italic">and existing brands</span>',
        content: `Whether you're looking to build a new brand or to revisit an existing one, we have a team of creatives who can help. We'll take you through a discovery process to better understand your business objectives, which will inform creative direction and brand strategy.`,
    },
    {
        desktopImage: slide2ImageDesktop,
        mobileImage: slide2ImageMobile,
        subtitle: 'Digital marketing services',
        heading: 'We produce<br /> <span class="italic">social first content</span>',
        content: `We understand social and the content that cuts through the noise. We encourage our clients to produce meaningful content, which in turn encourages more engagement and brand affinity from their audiences. In this fast moving industry, we're always looking to push brands into new formats, testing and learning to inform future strategy.`,
    },
    {
        desktopImage: slide3ImageDesktop,
        mobileImage: slide3ImageMobile,
        subtitle: 'Digital marketing services',
        heading: 'Email marketing<br /> <span class="italic">to drive ROI</span>',
        content:
            'Existing customers are often the most lucrative and through email marketing they are easy to reach. Email marketing allows you to share promotions or brand focuses with this audience on mass, which drives an unrivalled ROI across our client roster.',
    },
    {
        desktopImage: slide5ImageDesktop,
        mobileImage: slide5ImageMobile,
        subtitle: 'Digital marketing services',
        heading: 'Driving advocacy<br/> <span class="italic">through influencer marketing</span>',
        content:
            'Our campaign managers will work with you to determine the best influencer marketing approach to meet your brand objectives. They will then identify and pipeline influencers, looking at brand relevance, safety, reach and engagement. Once selected for the campaign, our team will manage the full process, including contracting, content production, approvals and reporting.',
    },
];
