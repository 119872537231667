import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import MastheadLandingPage from '../components/content/mastheads/landingPage';
import LogoAndText from '../components/content/LogoAndText';
import IntroText from '../components/content/IntroText';
import WithImageCarousel from '../components/content/withImage/carousel';
import { sliderData } from '../components/custom/digitalMarketing/sliderData';
// import BigContent3 from "../components/content/BigContent/3"
import PageNavigationBlock from '../components/content/pageNavigationBlock';

import mastheadImageWebp from '../images/digital-marketing/digital-marketing-page-masthead.webp';
import mastheadImagePng from '../images/digital-marketing/digital-marketing-page-masthead.png';

import pageNavigationBlockImage from '../images/home/slides/home-slide-4.png';

const DigitalMarketingPage = ({ transitionStatus, ...props }) => {
    useEffect(() => {
        console.log('Digital Marketing Page', transitionStatus);
    }, [transitionStatus]);

    const data = useStaticQuery(graphql`
        {
            contentItem1Icon: file(name: { eq: "website-design" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 90, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            contentItem2Icon: file(name: { eq: "digital-artworker" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 90, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            contentItem3Icon: file(name: { eq: "account-manager" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 90, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            withImageStaticMobile: file(
                name: { eq: "withImageStatic--mobile" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            withImageStaticDesktop: file(
                name: { eq: "withImageStatic--desktop" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            bigContent3OneThird1Image: file(
                name: { eq: "bigContent3OneThird1" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 620
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            bigContent3OneThird2Image: file(
                name: { eq: "bigContent3OneThird2" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 620
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            bigContent3OneThird3Image: file(
                name: { eq: "bigContent3OneThird3" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 620
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <Layout path={props.path}>
            <Seo title="Digital Marketing" />
            <MastheadLandingPage
                subheading="Digital Marketing"
                heading="<div class='line'><span>We</span></div> <div class='line'><span>think</span></div> <div class='line'><span class='neon-pink'>digital</span></div>"
                content="We use intelligent techniques to make informed choices, serving the right message, to the right people, at the right time."
                imageWebp={mastheadImageWebp}
                imagePng={mastheadImagePng}
                backgroundColor="#ECECEC"
                textColor="#0E1828"
                gradient="linear-gradient(271.83deg, #874C98 12.09%, #8E4A97 14.03%, #A94192 23.73%, #BC3B8F 34.4%, #C7378D 44.1%, #CB368C 54.77%, #E32C59 75.14%, #E6273C 99.39%)"
            />
            <LogoAndText
                heading="Whatever your<br/> needs, we've got<br/> <span class='italic'>the team to help you</span>"
                contentItem1Icon={data.contentItem1Icon}
                contentItem1Heading="Website Design"
                contentItem1Description="Your brand comes to life through your website. Our creatives will work with you to build a digital first design, and our technical team will ensure that your website is a finely tuned, robust machine."
                contentItem2Icon={data.contentItem2Icon}
                contentItem2Heading="Email Marketing"
                contentItem2Description="With the ability to reach your customers whenever you want, email marketing is arguably the most cost effective, targeted, responsive and measurable marketing channel available to you."
                contentItem3Icon={data.contentItem3Icon}
                contentItem3Heading="Social Media"
                contentItem3Description="We'll get your brand social ready, producing thumb stopping, engaging content to capture the attention of your audience."
            />
            <IntroText
                background="white"
                heading="You're in the right place"
                content="We're constantly helping clients reach their business targets, whether that's transforming your brand, building a media presence or driving ROI through website performance."
            />
            <WithImageCarousel background="grey" data={sliderData} />
            {/* <BigContent3
        subtitle="More services of a different category"
        heading="We get to know you, design a<br/> solution, and execute it for you."
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam rhoncus mauris eu orci eleifend viverra. Sed vitae orci elementum, molestie leo sit amet, pharetra ex."
        oneThird1Image={data.bigContent3OneThird1Image}
        oneThird1Heading="We can review your user journey"
        oneThird1Content="Neque risus convallis et interdum aliquam sed. Sed in tristique sit eu sem. Non erat pretium, quis facilisis egestas. Eget in suscipit sit nunc morbi porttitor."
        oneThird2Image={data.bigContent3OneThird2Image}
        oneThird2Heading="Hold workshops to determine your goals"
        oneThird2Content="Neque risus convallis et interdum aliquam sed. Sed in tristique sit eu sem. Non erat pretium, quis facilisis egestas. Eget in suscipit sit nunc morbi porttitor."
        oneThird3Image={data.bigContent3OneThird3Image}
        oneThird3Heading="Review your brand and strategy"
        oneThird3Content="Neque risus convallis et interdum aliquam sed. Sed in tristique sit eu sem. Non erat pretium, quis facilisis egestas. Eget in suscipit sit nunc morbi porttitor."
      /> */}
            <PageNavigationBlock
                backgroundColour="white"
                overlayTextColour="#E2246E"
                heading="We are problem solvers"
                subtitle="Next up - About"
                content="We’re a collaborative team of people all who share the same goal of getting things done and improving your business."
                image={pageNavigationBlockImage}
                linkText="Learn more about us"
                link="/about"
            />
        </Layout>
    );
};

export default DigitalMarketingPage;
