import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import LogoNoText from '../../../images/logos/logo-no-text.png';

const Wrapper = styled.section`
    display: block;
    width: 100%;
    background: url(${LogoNoText}) left -130px top -260px/460px auto no-repeat #0e1828;
    color: #fff;
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            padding: 6rem 4rem;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                &.heading-column {
                    margin-bottom: 7rem;
                }
                h2 {
                    display: block;
                    font-family: 'Poppins';
                    font-size: 3.8rem;
                    line-height: 1.2;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    > span.italic {
                        font-family: 'Source Serif Pro';
                        font-weight: 300;
                        font-style: italic;
                    }
                }
                ul.content-list {
                    display: block;
                    width: 100%;
                    list-style: none;
                    margin: 0;
                    li {
                        display: flex;
                        flex-flow: column nowrap;
                        width: 100%;
                        margin-bottom: 4rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .icon {
                            display: block;
                            flex: 0 1 auto;
                            width: 100%;
                            max-width: 90px;
                            height: auto;
                            margin-bottom: 1rem;
                        }
                    }
                }
                .content {
                    display: block;
                    flex: 0 1 auto;
                    > h3 {
                        font-family: 'Poppins';
                        font-size: 1.6rem;
                        line-height: 1.5;
                        font-weight: 600;
                        margin: 0 0 0.5rem;
                    }
                    > p {
                        font-family: 'Poppins';
                        font-size: 1.4rem;
                        line-height: 1.5;
                        font-weight: 300;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        background: url(${LogoNoText}) left -180px top -275px / auto 768px no-repeat #0e1828;
        .outer-wrapper {
            .inner-wrapper {
                padding: 0;
                min-height: 470px;
                .column {
                    padding: 10rem 12rem;
                    &.heading-column {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: center;
                        padding: 15rem 12rem;
                        margin-bottom: 0;
                    }
                    h2 {
                        font-size: 5.4rem;
                        flex: 0 1 auto;
                    }
                    ul.content-list {
                        li {
                            flex-flow: row nowrap;
                            align-items: center;
                            .icon {
                                margin-right: 2.5rem;
                                margin-bottom: 0;
                            }
                            .content {
                                h3 {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        background: url(${LogoNoText}) left -180px center/auto 768px no-repeat #0e1828;
        .outer-wrapper {
            .inner-wrapper {
                flex-flow: row nowrap;
                align-items: center;
                padding: 10rem 6rem;
                min-height: 600px;
                .column {
                    padding: 0 0 0 5rem;
                    width: 50%;
                    &.heading-column {
                        padding: 0 5rem 0 0;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1440px) {
        .outer-wrapper {
            .inner-wrapper {
                padding: 10rem 18rem;
            }
        }
    }
`;

const LogoAndText = ({ ...props }) => {
    return (
        <Wrapper>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column heading-column">
                        <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                    </div>
                    <div className="column">
                        <ul className="content-list">
                            <li className="content-item">
                                {props.contentItem1Icon ? (
                                    <div className="icon">
                                        <GatsbyImage
                                            image={
                                                props.contentItem1Icon.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                            objectFit="contain"
                                        />
                                    </div>
                                ) : (
                                    ``
                                )}
                                <div className="content">
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem1Heading,
                                        }}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem1Description,
                                        }}
                                    />
                                </div>
                            </li>
                            <li className="content-item">
                                {props.contentItem2Icon ? (
                                    <div className="icon">
                                        <GatsbyImage
                                            image={
                                                props.contentItem2Icon.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    ``
                                )}
                                <div className="content">
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem2Heading,
                                        }}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem2Description,
                                        }}
                                    />
                                </div>
                            </li>
                            <li className="content-item">
                                {props.contentItem3Icon ? (
                                    <div className="icon">
                                        <GatsbyImage
                                            image={
                                                props.contentItem3Icon.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    ``
                                )}
                                <div className="content">
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem3Heading,
                                        }}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.contentItem3Description,
                                        }}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default LogoAndText;
