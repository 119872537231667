import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../css/slick.css';

function CustomPrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            Previous
            <svg viewBox="0 0 44 18" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.29409 8.0753L43.9189 8.0753L43.9189 10.2163L6.29409 10.2163L6.29409 8.0753Z"
                    fill="#0E1828"
                />
                <path
                    d="M11.3528 17.3662L8.5072 9.14565L11.3528 0.925096L0.918992 9.14565L11.3528 17.3662Z"
                    fill="#0E1828"
                />
            </svg>
        </button>
    );
}
function CustomNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick}>
            Next
            <svg viewBox="0 0 44 18" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.1376 10.2162L0.512695 10.2162L0.512695 8.0752L38.1376 8.0752L38.1376 10.2162Z"
                    fill="#0E1828"
                />
                <path
                    d="M33.0789 0.925293L35.9244 9.14585L33.0789 17.3664L43.5126 9.14585L33.0789 0.925293Z"
                    fill="#0E1828"
                />
            </svg>
        </button>
    );
}

const imageCarouselSettings = {
    dots: true,
    nav: true,
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '250px',
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                centerPadding: '40px',
                nav: false,
                nextArrow: null,
                prevArrow: null,
            },
        },
    ],
};
const contentCarouselSettings = {
    dots: false,
    nav: false,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    fade: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                adaptiveHeight: true,
            },
        },
    ],
};

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    color: #0e1828;
    &.grey {
        background: #ececec;
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            .row-wrapper {
                display: block;
                position: relative;
                width: 100%;
                &.row-one {
                    padding: 7rem 4rem 2rem 4rem;
                    button.slick-arrow {
                        display: none !important;
                    }
                }
                .slide {
                    .slide-container {
                        display: flex;
                        flex-flow: column nowrap;
                        .column {
                            display: block;
                            width: 100%;
                            flex: 0 1 auto;
                            &.full-width {
                                width: 100%;
                            }
                            .subtitle {
                                display: block;
                                font-family: 'Poppins';
                                font-size: 1rem;
                                line-height: 3.25rem;
                                font-weight: 600;
                                letter-spacing: 0.3em;
                                text-transform: uppercase;
                                margin-bottom: 1.5rem;
                            }
                            h2 {
                                display: block;
                                width: 100%;
                                font-family: 'Poppins';
                                font-size: 3.8rem;
                                line-height: 1.2;
                                font-weight: 600;
                                margin: 0 0 2.4rem 0;
                                > span {
                                    &.light {
                                        font-weight: 300;
                                    }
                                    &.italic {
                                        font-family: 'Source Serif Pro';
                                        font-size: 4.4rem;
                                        line-height: 1.05;
                                        font-weight: 400;
                                        font-style: italic;
                                    }
                                }
                            }
                            .content {
                                display: block;
                                font-family: 'Poppins';
                                > p {
                                    font-size: 1.4rem;
                                    line-height: 1.5;
                                    font-weight: 300;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    &.row-one {
                        padding: 10rem 12rem 4rem 12rem;
                        align-items: center;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    &.row-one {
                        padding-bottom: 0;
                    }
                    .slide {
                        .slide-container {
                            display: flex;
                            flex-flow: row wrap;
                            .column {
                                width: calc(50% - 2rem);
                                margin-right: 4rem;
                                &:last-child {
                                    margin-right: 0;
                                    margin-top: 5rem;
                                }
                                h2 {
                                    font-size: 4.8rem;
                                    line-height: 1.1;
                                    margin-bottom: 0;
                                    > span {
                                        &.italic {
                                            font-size: 5.4rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
const ImageCarouselWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 2rem 0 7rem 0;
    @media only screen and (min-width: 768px) {
        padding: 4rem 0 10rem 0;
    }
    @media only screen and (min-width: 1024px) {
        padding: 4rem 0 10rem 0;
    }
`;
const WithImageCarousel = ({ ...props }) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <Wrapper className={props.background}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <Slider
                        {...contentCarouselSettings}
                        className="row-wrapper row-one overflow-hidden"
                        ref={(slider1) => setNav1(slider1)}
                        asNavFor={nav2}
                    >
                        {props.data.map((slide, index) => {
                            return (
                                <div className="slide" key={index}>
                                    <div className="slide-container">
                                        <div className="column">
                                            <div
                                                className="subtitle"
                                                dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                                            />
                                            <h2
                                                dangerouslySetInnerHTML={{ __html: slide.heading }}
                                            />
                                        </div>
                                        <div className="column">
                                            <div className="content">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: slide.content,
                                                    }}
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
            <ImageCarouselWrapper>
                <Slider
                    {...imageCarouselSettings}
                    className="overflow-hidden"
                    ref={(slider2) => setNav2(slider2)}
                    asNavFor={nav1}
                >
                    {props.data.map((slide, index) => {
                        return (
                            <picture key={index}>
                                <source
                                    srcSet={slide.mobileImage}
                                    media="(max-width: 1023px)"
                                    type="image/jpg"
                                />
                                <source
                                    srcSet={slide.desktopImage}
                                    media="(min-width: 1024px)"
                                    type="image/jpg"
                                />
                                <img src={slide.mobileImage} alt="" />
                            </picture>
                        );
                    })}
                </Slider>
            </ImageCarouselWrapper>
        </Wrapper>
    );
};

export default WithImageCarousel;
